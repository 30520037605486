body {
  background-image: url(./images/darkbkg.jpg);
  background-position: center;
  background-size: cover;
  color: white;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

nav {
  display: flex;
  background-color: #28c7fa;
  justify-content: space-between;
}

nav .logo h1 {
  color: whitesmoke;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin: 10px;
  color: #fff;
}

nav ul .nav-item {
  display: flex;
  justify-content: center;
  list-style: none;
  text-decoration: none;
}

header {
  height: 20vh;
  background-color: #775ada;
  display: flex;
  align-items: center;
}

header .hero {
  color: white;
  font-size: 25px;
  height: 140px;
  width: auto;
  padding: 10px;
  margin: 10px;
}

.contact, h3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  align-self: center;
}

form button {
  background-color: #331d7e;
  color: white;
  width: fit-content;
  padding: 5px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  margin: 5px;
  text-align: center;
}

form input {
  height: 15px;
  margin: 3px;
  padding: 3px 7px;
  font-size: 15px;
  outline: none;
}

#form-text p {
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  display: flex;
  justify-content: center;
  color: #331d7e;
}

section h3 {
  text-align: center;
  background-color: #775ada;
  width: 200px;
  height: auto;
  align-items: center;
  border: 2px solid #331d7e;
}

section h2 {
  text-align: center;
  background-color: #775ada;
  width: 200px;
  height: auto;
  align-items: center;
}

.asidebar h4 {
  background-color: #775ada;
  width: fit-content;
  display: flex;
}

aside {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.aside ul li a {
  display: flex;
  text-decoration: none;
  list-style: none;
}

.foot {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid slateblue;
  background-color: #28c7fa;
}

.foot ul {
  display: grid;
  grid-template-columns: repeat(3)1fr;
  list-style: none;
}

.foot ul li {
  color: #fff;
  margin: 8px;
}

.foot ul li a {
  color: #002651;
  font-weight: bold;
}

.foot h3 {
  display: flex;
  justify-content: center;
}

.foot ul li a {
  display: flex;
  text-decoration: none;
}

.foot #partner {
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.fo-title h3 {
  border: 2px solid #002651;
  background-color: slateblue;
}

#befoot {
  display: flex;
  justify-content: center;
}

#cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%
}

.cards {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(2) 1fr;
  justify-content: space-between;
  align-items: center;
}

.cards img {
  margin-top: 20px;
  display: grid;
  height: 300px;
  width: 250px;
  border: 2px solid slateblue;
  border-radius: 10px 10px 0px 10px;
}

.cards h3 {
  text-align: center;
  border-radius: 12px 0 15px 11px;
  background-color: #775ada;
  color: white;
  height: 25px;
  width: auto;
  margin-top: 0px;
}